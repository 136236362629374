.verification {
  .ant-tabs-nav .ant-tabs-nav-wrap {
    height: @header-size !important;
    background-color: @white;
    border-bottom: 1px solid @light-purple;
    &:extend(.ph-4);
  }
  .ant-tabs-nav {
    &:extend(.mb-0);
  }
  .ant-tabs-content-holder {
    &:extend(.p-4);
  }
}
