.challenge-submission-thumbnail {
  position: relative;
  height: 112px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-play-submission {
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.hype-form {
  .input-hype-name {
    .ant-input {
      padding: 4px 1px;
      &:extend(.text-size-32);
      &:extend(.text-weight-bold);
    }
  }
  .ant-form-item-label {
    label {
      height: auto !important;
    }
  }
  .ant-form-item-label {
    &:extend(.pb-0);
  }

  .input-path {
    .ant-form-item {
      &:extend(.m-0);
    }
  }

  .media-container {
    position: relative;
  }

  .btn-delete-media {
    position: absolute;
    z-index: 1;
    right: -12px;
    top: -12px;
  }
}

.challenge-steps {
  ol {
    &:extend(.m-0);
    &:extend(.pl-2);
  }
}

.challenge-tier-container {
  &:extend(.bg-purple-bg);
  &:extend(.mt-10);
  height: 24px;
  display: flex;
  border-radius: 4px;

  .challenge-tier-prefix {
    &:extend(.ph-1);
    &:extend(.bg-divider);
    display: flex;
    align-items: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: calc(100% - 2px);
    margin: 1px 0;
  }
  .challenge-tier-wrapper {
    &:extend(.pr-2);
    flex: 1;
    display: flex;
    position: relative;

    .challenge-tier-item {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      z-index: 2;

      .challenge-tier-item-tooltip {
        .ant-tooltip-inner,
        .ant-tooltip-arrow .ant-tooltip-arrow-content {
          &:extend(.text-color-primary-purple);
          &:extend(.bg-divider);
          min-height: unset !important;
        }
      }
    }
  }
}

.input-hidden {
  .ant-form-item-control-input {
    min-height: 0px !important;
  }
}

.hypes-form-collapsible-container {
  .ant-collapse-header {
    background: #edf1ff;
    border-radius: 8px;
  }
}

.challenge-rule-section-switch-input-container {
  min-height: 75px;
}

.circle-stat {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.hypes-isactive-switch {
  float: right;
  label::after {
    content: '' !important;
  }
}
.hypes-form-etc-form {
  border-radius: 6px;
  border-width: 2px;
  input {
    height: 30px;
  }
  .ant-input-number-input-wrap {
    input {
      height: 40px;
    }
  }
}
.button-submit {
  background: @primary-purple;
  border: solid 1px @primary-purple;
  font-weight: 600;
}
.button-submit[disabled] {
  background: @neutral-40;
  border: solid 1px @neutral-40;
  color: @neutral-70;
}
.hypes-form-add-rule-submit {
  font-weight: 600;
  border-radius: 4px;
}
.hypes-form-add-rule-submit[disabled] {
  background: @neutral-40;
  border: solid 1px @neutral-40;
  color: @neutral-70;
}
.hypes-form-add-rule-cancel {
  color: @primary-purple;
  font-weight: 600;
}
